/*
 * @Description: reduxKey
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-20 11:10:24
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-03 14:14:34
 */
const actionName = {
  tableLoading: {
    name: 'tableLoading',
    status: false
  }, //表格加载
  tableChildLoading: {
    name: 'tableChildLoading',
    status: false
  }, //表格子级加载
  buttonLoading: {
    name: 'buttonLoading',
    status: false
  }
}
export default actionName
