/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-20 11:05:27
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-02 01:06:19
 */

import actionName from './actionsName'
export const tableLoading = (value: boolean) => ({ type: actionName.tableLoading.name, value }) //tableLoading
export const tableChildLoading = (value: boolean) => ({
  type: actionName.tableChildLoading.name,
  value
}) //tableChildLoading
export const buttonLoading = (value: boolean) => ({ type: actionName.buttonLoading.name, value }) //tableChildLoading
