/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-10 00:57:27
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-31 15:24:11
 */
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@/store";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
