/*
 * @Description:session调用
 * @Author: tianyu
 * @Email: jackTian9527@163.com
 * @Date: 2021-02-02 14:39:39
 * @LastEditors: tianyu
 * @LastEditTime: 2021-02-21 23:54:10
 */
class session {
  //会话缓存获取
  get(key: string) {
    let data: any = sessionStorage.getItem(key)
    try {
      return JSON.parse(data)
    } catch (data) {
      return data
    }
  }
  //会话缓存写入
  set(key: string, val: any) {
    let dataType = Object.prototype.toString.call(val)
    let data = dataType.includes('Array') || dataType.includes('Object') ? JSON.stringify(val) : val
    return sessionStorage.setItem(key, data)
  }
  //会话缓存删除
  remove(key: string) {
    return sessionStorage.removeItem(key)
  }
  //会话缓存全部清除
  clear() {
    return sessionStorage.clear()
  }
  //持久缓存获取
  getLoca(key: string) {
    let data: any = localStorage.getItem(key)
    try {
      return JSON.parse(data)
    } catch (data) {
      return data
    }
  }
  //持久缓存写入
  setLoca(key: string, val: any) {
    let dataType = Object.prototype.toString.call(val)
    let data = dataType.includes('Array') || dataType.includes('Object') ? JSON.stringify(val) : val
    return localStorage.setItem(key, data)
  }
  //会话缓存删除
  removeLoca(key: string) {
    return localStorage.removeItem(key)
  }
  //会话缓存全部清除
  clearLoca() {
    return localStorage.clear()
  }
  //退出清除缓存
  loginOut() {
    this.clear()
    this.clearLoca()
    window.location.href = '/login'
  }
}
export default new session()
