/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-20 11:03:53
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-09 13:44:30
 */
import actionName from '@/store/actions/actionsName'
const initialData = {
  name: 'FINACE',
  ...actionName
}
function reducer(state = initialData, action: any) {
  var params = state
  switch (action.type) {
    case actionName.tableLoading.name: //表格加载
      params.tableLoading.status = action.value
      return params
    case actionName.tableChildLoading.name: //子表格加载
      params.tableChildLoading.status = action.value
      return params
    case actionName.buttonLoading.name: //edit加载
      params.buttonLoading.status = action.value
      return params
    default:
      return state
  }
}

export default reducer
